<template>
  <div :class="`accordion flex flex-col gap-4 rounded-lg p-4 border transition-all duration-300 ${isOpen ? `max-h-auto opened` : `h-58`}`">
    <div class="accordion-header cursor-pointer" @click="toggle">
      <div :class="`flex items-center justify-between accordion-title semibold ${isOpen ? `border-b pb-2 opened` : ``}`">
        <div class="flex gap-2">
          <div :class="{ hidden: !enabelIconLabel }">
            <Label color="yellow" />
          </div>
          {{ title }}
        </div>
        <Chevron :direction="`${isOpen ? `down` : `right`}`" height="14" />
      </div>
    </div>
    <div :class="`accordion-content ${isOpen ? ` opened` : `hidden`}`">
      <slot></slot>
      <!-- <component :is="content" :options="combinedData" :modeForm="modeForm" /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    enabelIconLabel: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
    // content: {
    //   type: Object,
    //   required: false
    // },
    // options: {
    //   required: false
    // },
    // modeForm: {
    //   required: false
    // }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  },
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Label: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Label')
  }
}
</script>

<style scoped>
.max-h-auto {
  max-height: fit-content;
}
.h-58 {
  height: 58px;
}
</style>
