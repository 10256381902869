var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "accordion flex flex-col gap-4 rounded-lg p-4 border transition-all duration-300 ".concat(_vm.isOpen ? "max-h-auto opened" : "h-58")
  }, [_c('div', {
    staticClass: "accordion-header cursor-pointer",
    on: {
      "click": _vm.toggle
    }
  }, [_c('div', {
    class: "flex items-center justify-between accordion-title semibold ".concat(_vm.isOpen ? "border-b pb-2 opened" : "")
  }, [_c('div', {
    staticClass: "flex gap-2"
  }, [_c('div', {
    class: {
      hidden: !_vm.enabelIconLabel
    }
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.title) + " ")]), _c('Chevron', {
    attrs: {
      "direction": "".concat(_vm.isOpen ? "down" : "right"),
      "height": "14"
    }
  })], 1)]), _c('div', {
    class: "accordion-content ".concat(_vm.isOpen ? " opened" : "hidden")
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }